import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'allFeedTab',
    'defaultFeedTab',
    'handsOnFeedTab',
    'forProjectFeedTab',
    'allFeed',
    'defaultFeed',
    'handsOnFeed',
    'forProjectFeed'
  ]

  connect() {}

  switchToallFeed() {
    const defaultFeedTab = this.defaultFeedTabTarget
    const defaultFeed = this.defaultFeedTarget
    const handsOnFeedTab = this.handsOnFeedTabTarget
    const handsOnFeed = this.handsOnFeedTarget
    const forProjectFeedTab = this.forProjectFeedTabTarget
    const forProjectFeed = this.forProjectFeedTarget
    this.hideFeeds(
      [defaultFeedTab, handsOnFeedTab, forProjectFeedTab],
      [defaultFeed, handsOnFeed, forProjectFeed]
    )

    const allFeedTab = this.allFeedTabTarget
    const allFeed = this.allFeedTarget
    this.showFeed([allFeedTab], [allFeed])
  }

  switchToDefaultFeed() {
    const allFeedTab = this.allFeedTabTarget
    const allFeed = this.allFeedTarget
    const handsOnFeedTab = this.handsOnFeedTabTarget
    const handsOnFeed = this.handsOnFeedTarget
    const forProjectFeedTab = this.forProjectFeedTabTarget
    const forProjectFeed = this.forProjectFeedTarget
    this.hideFeeds(
      [allFeedTab, handsOnFeedTab, forProjectFeedTab],
      [allFeed, handsOnFeed, forProjectFeed]
    )

    const defaultFeedTab = this.defaultFeedTabTarget
    const defaultFeed = this.defaultFeedTarget
    this.showFeed([defaultFeedTab], [defaultFeed])
  }

  switchToHandsOnFeed() {
    const allFeedTab = this.allFeedTabTarget
    const allFeed = this.allFeedTarget
    const defaultFeedTab = this.defaultFeedTabTarget
    const defaultFeed = this.defaultFeedTarget
    const forProjectFeedTab = this.forProjectFeedTabTarget
    const forProjectFeed = this.forProjectFeedTarget
    this.hideFeeds(
      [defaultFeedTab, allFeedTab, forProjectFeedTab],
      [defaultFeed, allFeed, forProjectFeed]
    )

    const handsOnFeedTab = this.handsOnFeedTabTarget
    const handsOnFeed = this.handsOnFeedTarget
    this.showFeed([handsOnFeedTab], [handsOnFeed])
  }

  switchToForProjectFeed() {
    const allFeedTab = this.allFeedTabTarget
    const allFeed = this.allFeedTarget
    const defaultFeedTab = this.defaultFeedTabTarget
    const defaultFeed = this.defaultFeedTarget
    const handsOnFeedTab = this.handsOnFeedTabTarget
    const handsOnFeed = this.handsOnFeedTarget
    this.hideFeeds(
      [defaultFeedTab, allFeedTab, handsOnFeedTab],
      [defaultFeed, allFeed, handsOnFeed]
    )

    const forProjectFeedTab = this.forProjectFeedTabTarget
    const forProjectFeed = this.forProjectFeedTarget
    this.showFeed([forProjectFeedTab], [forProjectFeed])
  }

  hideFeeds(tabs, feeds) {
    tabs.forEach((tab) => {
      tab.classList.remove('tab-item-active')
    })

    feeds.forEach((feed) => {
      feed.style.display = 'none'
    })
  }

  showFeed(tabs, feeds) {
    tabs.forEach((tab) => {
      tab.classList.add('tab-item-active')
    })

    feeds.forEach((feed) => {
      feed.style.display = 'block'
    })
  }
}
